@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tailwind extension for the project */
@layer base {
  /* Adding own styles */

  @font-face {
    font-family: "BullBear";
    font-style: normal;
    font-display: swap;
    src: url("/src/fonts/BullBear.otf") format("opentype");
  }
}

* {
  font-family: "BullBear", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

.border-shadow {
  box-shadow: inset 5px 7px 14px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

body {
  background-color: rgb(255 219 165);
}

.scrollbar-leaderboard::-webkit-scrollbar {
  width: 6px;
}

.scrollbar-leaderboard::-webkit-scrollbar-track {
  background: #9f5d1f;
}

.scrollbar-leaderboard::-webkit-scrollbar-thumb {
  background: #fecd9f;
}